@tailwind base;
@tailwind components;

html, body {
  overscroll-behavior: none;
}

html, body {
  height: 100vh;
  width: 100%;
}

@tailwind utilities;
